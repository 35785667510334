.mapboxgl-popup-content {
  background: transparent;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-tip {
  margin-top: -16px;
}
