.swiper-pagination-bullet-active {
  background: #ffffff90 !important;
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  color: black !important;
  transition: 0.3s;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 12px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #ffffff94 !important;
}
