@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 87 18% 50%;
    --primary-foreground: 0 0% 98%;

    --secondary: 210 40% 96%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 210 40% 96%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 210 40% 96%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --radius: 0.5rem;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
  }
}
